import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(-11.000000, -4.000000)">
          <path
            d="M 45.445 29.020 C 41.123 30.344, 37.923 34.154, 37.313 38.701 C 36.573 44.223, 40.086 48.170, 48.174 50.901 C 55.892 53.508, 58 55.381, 58 59.632 C 58 64.474, 54.662 66.377, 47.243 65.767 C 44.084 65.506, 40.836 65.115, 40.024 64.897 C 39.113 64.652, 38.420 65.330, 38.215 66.667 C 37.924 68.567, 38.581 68.979, 43.544 70.009 C 53.473 72.070, 61.010 69.036, 63.075 62.146 C 64.093 58.746, 63.996 57.659, 62.379 54.348 C 60.804 51.123, 59.529 50.134, 54.500 48.237 C 44.493 44.463, 43.533 43.775, 43.180 40.125 C 42.660 34.751, 45.300 32.695, 51.998 33.259 C 55.024 33.513, 58.288 34.058, 59.250 34.468 C 60.649 35.064, 61 34.712, 61 32.714 C 61 31.339, 60.330 29.957, 59.512 29.643 C 56.462 28.473, 48.398 28.115, 45.445 29.020"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
